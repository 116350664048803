import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import AppBase from "../AppBase";
import config from "../../config";
import theme from "../../theme";
import Footer from "../Footer";
import Header from "../Header";
import "../../fonts/proximaNova/100.css";
import "../../fonts/proximaNova/300.css";
import "../../fonts/proximaNova/400.css";
import "../../fonts/proximaNova/600.css";
import "../../fonts/proximaNova/700.css";
import "../../fonts/proximaNova/900.css";
// Test Comment

const Layout = ({ children, ...rest }) => {
  const [hasDom, setHasDom] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") setHasDom(true);
  }, []);
  return (
    hasDom && (
      <AppBase config={config} theme={theme}>
        <Header />
        <Box as="main">
          {typeof children === "function" ? children(rest) : children}
        </Box>
        <Footer />
      </AppBase>
    )
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default Layout;
