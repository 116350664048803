import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import Container from "../Container";

const Section = ({
  appendContent,
  bgImage,
  bgImageSx,
  children,
  containerSx,
  overlay,
  overlaySx,
  prependContent,
  sx,
  ...rest
}) => {
  const bgImageStyled = bgImage
    ? {
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        ...bgImageSx,
      }
    : {};
  const sectionStyled = {
    display: "flex",
    flexDirection: "column",
    mb: [0, null, null, null, 0],
    mt: [0, null, null, null, 0],
    pb: [5, null, null, null, 6],
    position: "relative",
    pt: [5, null, null, null, 6],
    px: [1, null, null, null],
    width: "100%",
    zIndex: 0,
    ...bgImageStyled,
    ...sx,
  };

  return (
    <Box as="section" sx={sectionStyled} {...rest}>
      {appendContent}
      {overlay ? (
        <Box
          sx={{
            bg: "primary",
            bottom: 0,
            left: 0,
            opacity: 0.9,
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: -1,
            ...overlaySx,
          }}
        />
      ) : null}
      <Container sx={containerSx}>{children}</Container>
      {prependContent}
    </Box>
  );
};

Section.propTypes = {
  appendContent: PropTypes.node,
  bgImage: PropTypes.string,
  bgImageSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  containerSx: PropTypes.shape({}),
  overlay: PropTypes.bool,
  overlaySx: PropTypes.shape({}),
  prependContent: PropTypes.node,
  sx: PropTypes.shape({}),
};

Section.defaultProps = {
  appendContent: null,
  bgImage: "",
  bgImageSx: {},
  containerSx: {},
  overlay: false,
  overlaySx: {},
  prependContent: null,
  sx: {},
};

export default Section;
