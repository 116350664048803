import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import Box from "../../base/Box";
import LinkTo from "../LinkTo";
import Icon from "../Icon";
import Image from "../Image";

const NavGroup = ({
  ignore,
  linkList,
  linkSx,
  activeLinkSx,
  menuSx,
  toggled,
}) => {
  const [activeNav, setActiveNav] = useState(null);
  const linkStyled = {
    alignItems: "center",
    cursor: "pointer",
    display: "inline-flex",
  };

  useEffect(() => {
    if (!toggled) {
      setActiveNav(null);
    }
  }, [toggled]);

  const hasLocation = (location, path) => {
    const { pathname } = location;
    return pathname.includes(path);
  };

  const setIconAndLabel = (icon, label) => (
    <>
      {icon && <Icon svg={icon} sx={{ width: "1.35rem" }} />}
      <Box as="span">{label}</Box>
    </>
  );

  const toggleActiveNav = (label) => {
    setActiveNav(activeNav ? null : label);
  };

  return (
    <Location>
      {({ location }) => (
        <Box as="ul" sx={menuSx}>
          {linkList &&
            linkList.length > 0 &&
            linkList.map((page) => {
              const isActive = activeNav === page.label;
              return !ignore.includes(page.label) ? (
                <Box
                  key={`nav-page-${page.label}`}
                  as="li"
                  className={isActive ? "active" : ""}
                  sx={
                    hasLocation(location, page.path)
                      ? {
                          ...linkSx,
                          ...activeLinkSx,
                        }
                      : linkSx
                  }
                >
                  {page.action ? (
                    <Box as="a" onClick={() => page.action()} sx={linkStyled}>
                      {setIconAndLabel(page.icon, page.label)}
                    </Box>
                  ) : (
                    <LinkTo partiallyActive sx={linkStyled} to={page.path}>
                      {setIconAndLabel(page.icon, page.label)}
                    </LinkTo>
                  )}

                  {page.submenu && page.submenu.length > 0 && (
                    <NavGroup linkList={page.submenu} />
                  )}
                  {page.submenu && (
                    <Image
                      onClick={() => toggleActiveNav(page.label)}
                      svgIcon="down"
                      sx={{
                        // color: isActive ? "primary" : "",
                        display: ["block", null, null, "none"],
                        position: "absolute",
                        right: 0,
                        transform: isActive ? "rotate(180deg)" : "",
                        transition: "transform .35s 0s",
                        width: "4.5rem",
                      }}
                    />
                  )}
                </Box>
              ) : null;
            })}
        </Box>
      )}
    </Location>
  );
};

NavGroup.propTypes = {
  activeLinkSx: PropTypes.shape({}),
  ignore: PropTypes.arrayOf(PropTypes.string),
  linkList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  linkSx: PropTypes.shape({}),
  menuSx: PropTypes.shape({}),
  toggled: PropTypes.bool,
};

NavGroup.defaultProps = {
  activeLinkSx: {},
  ignore: [],
  linkSx: {},
  menuSx: {},
  toggled: false,
};

export default NavGroup;
