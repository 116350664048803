import React from "react";
import PropTypes from "prop-types";
import NavBar from "../Navbar";

const Navigation = ({ navLinks, toggled, ...rest }) => {
  const navBarMobileStyled = {
    a: {
      "&:after": {
        borderRadius: 0,
        bottom: "0",
        boxShadow: "inset .4rem .4rem .4rem 0",
        color: "inherit",
        content: "''",
        height: ".1rem",
        left: 3,
        maxWidth: "0",
        position: "absolute",
        transform: ["translateX(0)", null, null, null, "translateX(0%)"],
        transition: "max-width 0.35s 0s",
        width: "100%",
      },
      "&:hover": {
        "&:after": {
          maxWidth: "calc(100% - 4rem)",
        },
        color: "blueDark",
      },
      fontFamily: "primary",
      fontSize: [3, null, null, null, 2],
      fontWeight: "light",
      letterSpacing: 1,
      my: [0, null, null, null, 3],
      px: "1.5rem",
      py: 2,
      textTransform: "capitalize",
    },
    bg: ["grayBorder", null, null, null, "unset"],
    bottom: ["0", null, null, null, "unset"],
    boxShadow: [3, null, null, null, "none"],
    color: ["white", null, null, null, "unset"],
    li: {
      color: "inherit",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    maxWidth: ["36rem", null, null, null, "100%"],
    overflow: ["hidden", null, null, null, "visible"],
    position: ["fixed", null, null, null, "static"],
    right: ["0", null, null, null, "unset"],
    top: ["0", null, null, null, "unset"],
    transform: [
      `${toggled ? "" : "translateX(100%)"} `,
      null,
      null,
      null,
      "none",
    ],
    transition: "transform .55s ease-in-out",
    ul: {
      flex: 1,
      flexDirection: ["column", null, null, null, "unset"],
      mx: [null, null, null, null, -3],
      p: [4, null, null, null, 0],
    },
    width: ["calc(100% - 4rem)", null, null, null, "auto"],
    zIndex: 2,
  };
  const navBarStyled = {
    alignItems: "center",
    display: "flex",
    flex: "auto",
    justifyContent: "center",
    ...navBarMobileStyled,
  };
  const menuStyled = {
    a: {
      "&:hover": {
        color: "blueDark",
        // fontFamily: "display",
      },
    },
    alignSelf: "center",
    display: "flex",
    justifyContent: "flex-end",
    li: {
      "&.active": {
        ul: {
          maxHeight: ["50rem", null, null, null, "0"],
        },
      },
      "&:hover": {
        ul: {
          maxHeight: [null, null, null, null, "50rem"],
        },
      },
      display: "flex",
      flexDirection: "column",
      position: "relative",
      ul: {
        a: {
          fontSize: [1, null, null, null, 0],
          py: 3,
        },
        bg: "white",
        boxShadow: [null, null, null, null, 2],
        color: [null, null, null, null, "primary"],
        maxHeight: "0rem",
        minWidth: [null, null, null, null, "22rem"],
        mx: 0,
        overflow: "hidden",
        position: [null, null, null, null, "absolute"],
        px: 1,
        py: 0,
        top: [null, null, null, null, "100%"],
        transition: "max-height .55s ease-in-out",
        zIndex: 2,
      },
    },
  };
  const activeLinkStyled = {
    a: {
      "&:after": {
        maxWidth: "calc(100% - 4rem)",
      },
      color: "blueDark",
      fontWeight: "bold",
      position: "relative",
    },
  };
  return (
    <NavBar
      activeLinkSx={activeLinkStyled}
      menuSx={menuStyled}
      navLinks={navLinks}
      sx={navBarStyled}
      toggled={toggled}
      {...rest}
    />
  );
};

Navigation.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggled: PropTypes.bool,
};

Navigation.defaultProps = {
  toggled: false,
};

export default Navigation;
