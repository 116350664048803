/* eslint-disable */
// NOTE: this file is generated automatically, any changes will be overwritten.

import Box from "../../base/Box";
import PropTypes from "prop-types";
import React from "react";
import brandlogo from "../../../static/assets/brand-logo.inline.svg";
import email from "../../../static/assets/email.inline.svg";
import facebooksm from "../../../static/assets/facebook-sm.inline.svg";
import facebook from "../../../static/assets/facebook.inline.svg";
import googlesm from "../../../static/assets/google-sm.inline.svg";
import iconarrow from "../../../static/assets/icon-arrow.inline.svg";
import iconcalendar from "../../../static/assets/icon-calendar.inline.svg";
import linkedInsm from "../../../static/assets/linkedIn-sm.inline.svg";
import linkedIn from "../../../static/assets/linkedIn.inline.svg";
import location from "../../../static/assets/location.inline.svg";
import map from "../../../static/assets/map.inline.svg";
import notfoundimage from "../../../static/assets/not-found-image.inline.svg";
import phone from "../../../static/assets/phone.inline.svg";
import twittersm from "../../../static/assets/twitter-sm.inline.svg";
import twitter from "../../../static/assets/twitter.inline.svg";

const svgMap = {
  "brand-logo": brandlogo,
  email: email,
  "facebook-sm": facebooksm,
  facebook: facebook,
  "google-sm": googlesm,
  "icon-arrow": iconarrow,
  "icon-calendar": iconcalendar,
  "linkedIn-sm": linkedInsm,
  linkedIn: linkedIn,
  location: location,
  map: map,
  "not-found-image": notfoundimage,
  phone: phone,
  "twitter-sm": twittersm,
  twitter: twitter,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    "brand-logo",
    "email",
    "facebook-sm",
    "facebook",
    "google-sm",
    "icon-arrow",
    "icon-calendar",
    "linkedIn-sm",
    "linkedIn",
    "location",
    "map",
    "not-found-image",
    "phone",
    "twitter-sm",
    "twitter",
  ]).isRequired,
};

export default Icon;
