import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import Image from "../Image";
import LinkTo from "../LinkTo";

const SocialLinks = ({ links, linkSx, iconSx, sx }) => (
  <Box
    sx={{
      display: "flex",
      mx: -1,
      my: 2,
      ...sx,
    }}
  >
    {links.map((sm, index) => (
      <LinkTo
        isLink={sm.link}
        sx={{
          "&:after": {
            display: "none !important",
          },
          "&:not(:hover)": {
            svg: {
              fill: "grays.6",
            },
          },
          mx: 1,
          ...linkSx,
        }}
        target="_blank"
      >
        <Image
          key={`${sm.icon}_${index}`}
          svgIcon={sm.icon}
          sx={{ height: "3rem", width: "3rem", ...iconSx }}
        />
      </LinkTo>
    ))}
  </Box>
);

SocialLinks.propTypes = {
  iconSx: PropTypes.shape({}),
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  linkSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

SocialLinks.defaultProps = {
  iconSx: {},
  linkSx: {},
  sx: {},
};

export default SocialLinks;
