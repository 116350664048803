import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import Heading from "../Heading";
import AspectRatio from "../AspectRatio";
import Image from "../Image";

const List = ({
  children,
  title,
  titleSx,
  listSx,
  sx,
  bullet,
  bulletSx,
  noBullet,
}) => {
  const wrapperStyled = {
    display: "flex",
    flexDirection: "column",
    ...sx,
  };
  const listStyled = {
    ":last-child": {
      mb: 0,
    },
    alignItems: "flex-start",
    display: "flex",
    mb: 2,
    ...listSx,
  };
  return (
    <Box sx={wrapperStyled}>
      {title && (
        <Heading sx={{ mb: 3, ...titleSx }} variant="headings.primary">
          {title}
        </Heading>
      )}
      <Box as="ul">
        {children && children.length > 0
          ? React.Children.map(children.filter(Boolean), (child) => (
              <Box as="li" sx={listStyled}>
                {!noBullet &&
                  (bullet || (
                    <AspectRatio
                      sx={{
                        flex: "none",
                        mr: 4,
                        mt: 1,
                        width: "16px",
                        ...bulletSx,
                      }}
                    >
                      <Image
                        alt=""
                        svgIcon="bullet-icon"
                        sx={{
                          bottom: 0,
                          left: "0",
                          position: "absolute",
                          right: 0,
                          top: "0",
                        }}
                      />
                    </AspectRatio>
                  ))}
                {typeof child === "string" ? (
                  <Box as="span">{child}</Box>
                ) : (
                  child
                )}
              </Box>
            ))
          : null}
      </Box>
    </Box>
  );
};

List.propTypes = {
  bullet: PropTypes.node,
  bulletSx: PropTypes.shape({}),
  children: PropTypes.node,
  listSx: PropTypes.shape({}),
  noBullet: PropTypes.bool,
  sx: PropTypes.shape({}),
  title: PropTypes.string,
  titleSx: PropTypes.shape({}),
};

List.defaultProps = {
  bullet: null,
  bulletSx: {},
  children: null,
  listSx: {},
  noBullet: false,
  sx: {},
  title: "",
  titleSx: {},
};

export default List;
