import React from "react";
import { graphql, StaticQuery } from "gatsby";
import List from "../List";
import BrandLogo from "../BrandLogo";
import FooterContainer from "../../containers/FooterContainer";
import Box from "../../base/Box";
import Section from "../Section";
import LinkTo from "../LinkTo";
import SocialLinks from "../socialLink";
import Heading from "../Heading";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            logo
            siteName
          }
        }
      }
    `}
    render={(data) => {
      const { siteName, logo } = data.site.siteMetadata;
      return (
        <FooterContainer>
          {(footerData, contactData, pagesData) => {
            const footerId = "footer";
            const footerInfo = footerData.find((data) => data.id === footerId);
            // const columnCount = footerInfo.hideSocialMedia ? 3 : 4;
            const contactInfo = contactData[0];
            const { socialmediacontacts } = footerInfo;
            contactData.map(
              (cn) => cn.contactTitle && Object.assign(contactInfo, cn)
            );
            const { pages } = pagesData.find((item) => item.id === "pages");
            const allPages = pages.filter((i) => !i.hidePage);
            const Contact =
              allPages[Object.keys(allPages)[Object.keys(allPages).length - 1]];

            return (
              <Section
                sx={{
                  bg: "grayBackground",
                  color: "grays.8",
                  mb: 0,
                  mt: 0,
                  pb: 5,
                  pt: 5,
                }}
              >
                <Box
                  sx={{
                    ":before": {
                      bg: "yellowDark",
                      content: "''",
                      height: "2px",
                      position: "absolute",
                      right: 0,
                      top: -5,
                      width: "100vw",
                    },
                    position: "relative",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    m: -3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: [null, null, null, 1],
                      flexDirection: "column",
                      p: 3,
                      textAlign: ["center", null, null, "left"],
                      width: "100%",
                    }}
                  >
                    <Box
                    // sx={{ display: "flex", flexDirection: "column" }}
                    //   listSx={{
                    //   fontSize: 1,
                    //   justifyContent: ["center", null, null, "flex-start"],
                    // }}
                    // noBullet
                    // title={footerInfo.linkTitle}
                    // titleSx={{ color: "yellowDark", fontSize: 2 }}
                    >
                      {!footerInfo.hideContactDetails && footerInfo.linkTitle && (
                        <Heading
                          sx={{ color: "yellowDark", fontSize: 2, mb: 3 }}
                          variant="headings.primary"
                        >
                          {footerInfo.linkTitle}
                        </Heading>
                      )}
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {allPages
                          .map((nav, index) =>
                            nav.title !== "Contact Us" ? (
                              <Box
                                sx={{
                                  mb: [0, null, null, 3],
                                  width: ["100%", null, null, "50%"],
                                }}
                              >
                                <LinkTo
                                  key={`${nav.title}'_'${index}`}
                                  activeSx={{ color: "grays.8" }}
                                  hoverSx={{ color: "grays.8" }}
                                  to={nav.url}
                                >
                                  {nav.title}
                                </LinkTo>
                              </Box>
                            ) : null
                          )
                          .filter((i) => i)}
                      </Box>
                    </Box>
                  </Box>
                  {!footerInfo.hideContactDetails && (
                    <Box
                      sx={{
                        display: "flex",
                        flex: [null, null, null, 1],
                        flexDirection: "column",
                        p: 3,
                        textAlign: ["center", null, null, "left"],
                        width: "100%",
                      }}
                    >
                      <List
                        listSx={{
                          fontSize: 1,
                          justifyContent: ["center", null, null, "flex-start"],
                        }}
                        noBullet
                        title={footerInfo.contactTitle}
                        titleSx={{ color: "yellowDark", fontSize: 2 }}
                      >
                        <LinkTo hoverSx={{ color: "white" }} to={Contact.url}>
                          {Contact.title}
                        </LinkTo>
                        {Object.keys(contactInfo).length > 0 && [
                          contactInfo.phonenumbers.length > 0 &&
                            contactInfo.phonenumbers.map((num, index) => (
                              <span key={`${num.number}-${index}`}>
                                Phone :{" "}
                                <LinkTo
                                  callTo={num.number}
                                  hoverSx={{ color: "white" }}
                                />
                              </span>
                            )),
                          contactInfo.emailsaddresses.length > 0 &&
                            contactInfo.emailsaddresses.map((email, index) => (
                              <span key={`${email.mailid}-${index}`}>
                                Email :{" "}
                                <LinkTo
                                  hoverSx={{ color: "white" }}
                                  mailTo={email.mailid}
                                />
                              </span>
                            )),
                        ]}
                      </List>
                    </Box>
                  )}
                  {!footerInfo.hideSocialMedia && (
                    <Box
                      sx={{
                        alignItems: ["center", null, null, "flex-start"],
                        display: "flex",
                        flex: [null, null, null, 1],
                        flexDirection: "column",
                        p: 3,
                        width: "100%",
                      }}
                    >
                      <Heading
                        sx={{
                          color: "yellowDark",
                          fontSize: 2,
                          mb: 3,
                        }}
                        variant="headings.primary"
                      >
                        {footerInfo.socialmediaTitle}
                      </Heading>
                      {socialmediacontacts.length > 0 && (
                        <SocialLinks
                          links={socialmediacontacts.map((sm) => ({
                            icon: sm.label,
                            link: sm.link,
                          }))}
                        />
                      )}
                    </Box>
                  )}
                  <Box
                    sx={{
                      alignItems: ["center", null, null, "flex-end"],
                      display: "flex",
                      flex: [null, null, null, 1],
                      flexDirection: "column",
                      justifyContent: "center",
                      ml: [null, null, "auto"],
                      p: 3,
                      width: "100%",
                    }}
                  >
                    <BrandLogo
                      alt={siteName}
                      path="/"
                      ratio={[94, 32]}
                      src={logo}
                      sx={{
                        bottom: 4,
                        color: "primary",
                        div: {
                          justifyContent: ["center", null, null, "flex-end"],
                        },
                        flex: "none",
                        mb: 4,
                        width: "17rem",
                      }}
                    />
                    <Box as="small" sx={{ fontSize: 0 }}>
                      © Copyright {new Date().getFullYear()}.{footerInfo.rights}
                    </Box>
                    <Box
                      as="small"
                      sx={{
                        color: "grays.8",
                        fontSize: 0,
                        fontWeight: "light",
                        mt: 2,
                      }}
                    >
                      <LinkTo
                        hoverSx={{ color: "yellowDark" }}
                        isLink={footerInfo.privacyPolicy.link}
                        sx={{ mx: 2 }}
                      >
                        {footerInfo.privacyPolicy.label}
                      </LinkTo>
                      <>|</>
                      <LinkTo
                        hoverSx={{ color: "yellowDark" }}
                        isLink={footerInfo.termsOfUse.link}
                        sx={{ mx: 2 }}
                      >
                        {footerInfo.termsOfUse.label}
                      </LinkTo>
                    </Box>
                  </Box>
                </Box>
              </Section>
            );
          }}
        </FooterContainer>
      );
    }}
  />
);

export default Footer;
