import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import NavGroup from "../NevGroup";

const NavBar = ({
  activeLinkSx,
  append,
  ignore,
  linkSx,
  menuSx,
  navLinks,
  prepend,
  sx,
  toggled,
}) => (
  <Box
    as="nav"
    sx={{
      ...sx,
    }}
  >
    {prepend}
    {
      <NavGroup
        activeLinkSx={activeLinkSx}
        ignore={ignore}
        linkList={navLinks}
        linkSx={linkSx}
        menuSx={menuSx}
        toggled={toggled}
      />
    }
    {append}
  </Box>
);

NavBar.propTypes = {
  activeLinkSx: PropTypes.shape({}),
  append: PropTypes.node,
  ignore: PropTypes.arrayOf(PropTypes.string),
  linkSx: PropTypes.shape({}),
  menuSx: PropTypes.shape({}),
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prepend: PropTypes.node,
  sx: PropTypes.shape({}),
  toggled: PropTypes.bool,
};

NavBar.defaultProps = {
  activeLinkSx: {},
  append: null,
  ignore: [],
  linkSx: {},
  menuSx: {},
  prepend: null,
  sx: {},
  toggled: false,
};

export default NavBar;
