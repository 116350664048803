import {NavMenuToggle} from "./src/helper/helper";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onRouteUpdate = () => {
  if (document.body.classList.contains('open-menu')) {
    NavMenuToggle()
  }
};