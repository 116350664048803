import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import Box from "../../base/Box";

const LinkTo = ({
  activeSx,
  callTo,
  children,
  hoverSx,
  isLink,
  isButton,
  mailTo,
  sx,
  target,
  to,
  partiallyActive,
  ...rest
}) => {
  const hasLocation = (location, path) => {
    const { pathname } = location;
    return pathname.includes(path);
  };
  const hoverStyled = isButton
    ? {}
    : {
        "&:hover": {
          "&:after": {
            maxWidth: "3rem",
          },
          color: "primary",
          ...hoverSx,
        },
      };
  // const hoverEffectSx = isButton
  //   ? {}
  //   : {
  //       "&:after": {
  //         borderRadius: 0,
  //         bottom: "0",
  //         boxShadow: "inset .4rem .4rem .4rem 0",
  //         color: "inherit",
  //         content: "''",
  //         height: ".1rem",
  //         left: "50%",
  //         maxWidth: "0",
  //         position: "absolute",
  //         transform: "translateX(-50%)",
  //         transition: "max-width 0.35s 0s",
  //         width: "100%",
  //         ...hoverSx,
  //       },
  //     };
  const linkStyled = {
    display: "inline-block",
    position: "relative",
    // ...hoverEffectSx,
    ...sx,
    ...hoverStyled,
  };
  const isCustomLink = isLink || callTo || mailTo;
  const setLink = (linkType) => {
    const { callTo, isLink, mailTo } = linkType;
    if (callTo) {
      return `tel:${callTo}`;
    }
    if (mailTo) {
      return `mailto:${mailTo}`;
    }
    return isLink;
  };
  return (
    <Location>
      {({ location }) =>
        isCustomLink ? (
          <Box
            as="a"
            href={setLink({
              callTo,
              isLink,
              mailTo,
            })}
            rel="noopener norefferer"
            sx={
              hasLocation(location, to)
                ? { ...linkStyled, ...activeSx }
                : linkStyled
            }
            target={target}
            {...rest}
          >
            {children || isCustomLink}
          </Box>
        ) : (
          <Box
            as={Link}
            partiallyActive={partiallyActive}
            sx={
              hasLocation(location, to)
                ? { ...linkStyled, ...activeSx }
                : linkStyled
            }
            to={to}
            {...rest}
          >
            {children}
          </Box>
        )
      }
    </Location>
  );
};

LinkTo.propTypes = {
  activeSx: PropTypes.shape({}),
  callTo: PropTypes.string,
  children: PropTypes.node.isRequired,
  hoverSx: PropTypes.shape({}),
  isButton: PropTypes.bool,
  isLink: PropTypes.string,
  mailTo: PropTypes.string,
  partiallyActive: PropTypes.bool,
  sx: PropTypes.shape({}),
  target: PropTypes.string,
  to: PropTypes.string,
};

LinkTo.defaultProps = {
  activeSx: {},
  callTo: "",
  hoverSx: {},
  isButton: false,
  isLink: "",
  mailTo: "",
  partiallyActive: false,
  sx: {},
  target: "",
  to: "/",
};

export default LinkTo;
