/* eslint-disable prefer-destructuring */

import { rgba } from "polished";

const brandBlue = "#214B5B";
const blueDark = "#0070A9";
const blueLight = "#00A7E1";
const blueSubtle = "#f5faff";
const yellowDark = "#F89627";
const blueDianne = "#254054";
const graySubtle = "#FCFCFC";
const grayBackground = "#F1F3F4";
const grayBorder = "#A3AAAE";

const green = "#34D399";
const yellow = "#FBBF24";
const red = "#F87171";
const white = "#ffffff";

const designElement = {
  leftBottom: {
    ":after": {
      bg: "yellowDark",
      bottom: ["-5px", null, null, "-10px"],
      content: "''",
      height: ["25px", null, null, "50px"],
      left: ["-5px", null, null, "-10px"],
      position: "absolute",
      width: ["50px", "120px", "120px", "180px"],
      zIndex: "-1",
    },
    position: "relative",
  },
  overLine: {
    ":before": {
      bg: "yellowDark",
      content: "''",
      height: "1px",
      left: "0px",
      position: "absolute",
      top: "-15px",
      width: ["0px", null, null, null, "80px"],
    },
    position: "relative",
  },
  rightBottom: {
    ":after": {
      bg: "yellowDark",
      bottom: ["-5px", null, null, "-10px"],
      content: "''",
      height: ["25px", null, null, "50px"],
      position: "absolute",
      right: ["-5px", null, null, "-10px"],
      width: ["50px", "120px", "120px", "180px"],
      zIndex: "-1",
    },
    position: "relative",
  },
};

const grays = [
  "#c8c8c8",
  "#787878",
  "#3B3B3B",
  "#353535",
  "#DEDEDE",
  "#13181A",
  "#A3AAAE",
  "#777777",
  "#555555",
];

const breakpoints = ["544px", "710px", "992px", "1200px", "1400px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const colors = {
  blueDark,
  blueDianne,
  blueLight,
  blueSubtle,
  body: white,
  borderLight: rgba(grays[0], 0.22),
  brandBlue,
  error: red,
  grayBackground,
  grayBorder,
  grays,
  graySubtle,
  highlight: yellowDark,
  primary: brandBlue,
  primaryLight: blueLight,
  primarySubtle: blueSubtle,
  success: green,
  text: {
    heading: brandBlue,
    para: grays[2],
    primary: grays[2],
    subtle: grays[0],
  },
  warning: yellow,
  white,
  whiteTransparent: rgba(white, 0.8),
  yellowDark,
};

const fonts = {
  // primary: '"Arial", "Helvetica", sans-serif',
  // primary: '"Open Sans", sans-serif',
  // primary: '"Gotham", sans-serif',
  // primary: '"Montserrat", sans-serif',
  primary: '"ProximaNova", sans-serif',
};

const lineHeights = ["normal", "1.25", "1.5", "2", "2.5", "3"];

const fontSizes = [
  "1.2rem",
  "1.4rem",
  "2rem",
  "3rem",
  "4rem",
  "5rem",
  "7.9rem",
  "8.2rem",
];

const fontWeights = {
  black: 900,
  bold: 700,
  light: 300,
  // Temporary changes made in fontsize
  // medium: 500,
  medium: 400,
  regular: 400,
  semiBold: 600,
};

const letterSpacings = [
  "0.13px",
  "0.56px",
  "0.6px",
  "0.68px",
  "0.75px",
  "1.2px",
  "1.25px",
];

const mediaQueries = {
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
};

const radii = [0, "0.25rem", "0.5rem", "1rem", "1.5rem", "9999px"];

const shadows = [
  "none",
  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
  "0px 3px 6px #00000038",
  "0px 6px 12px #00000038",
];

const sizes = {
  heights: { header: ["8.26rem", "10.6rem"] },
  maxWidths: {
    button: "14rem",
    lg: "992px",
    md: "710px",
    sm: "544px",
    xl: "1280px",
  },
};

const space = [
  "0",
  ".5rem",
  ".9rem",
  "2rem",
  "2.5rem",
  "5rem",
  "8.2rem",
  "10rem",
  "12rem",
  "15rem",
];

const zIndices = [0, 1, 10, 20, 50, 100, 200, 9999];

const button = {
  // "&:hover": { color: "yellowDark" },
  bg: "transparent",
  borderColor: "yellowDark",
  borderRadius: 0,
  borderStyle: "solid",
  borderWidth: "3px",
  color: "white",
  fontSize: 2,
  fontWeight: "light",
  letterSpacing: 1,
  px: 4,
  py: 2,
  textTransform: "capitalize",
  transition:
    "background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, border-color 0.2s, color 0.2s",
};

const transparentButton = {
  ...button,
  "&:hover": { bg: "primary", borderColor: "primary", color: "white" },
  bg: "inherit",
  borderColor: "textSubtle",
  color: "primary",
};

const variants = {
  buttons: {
    cancel: {
      ...transparentButton,
      "&:hover": { bg: "error", borderColor: "error", color: "white" },
      borderColor: "border",
      color: "border",
    },
    disabled: { opacity: ".5", pointerEvents: "none" },
    link: {
      "&:hover": { textDecoration: "underline" },
      py: 2,
      textAlign: "left",
      textDecoration: "none",
    },
    primary: { ...button },
    secondary: {
      ...button,
      "&:hover": { bg: "inherit", color: "blueDark" },
      bg: "blueDark",
      borderColor: "blueDark",
      borderRadius: "8px",
      color: "white",
      fontWeight: "bold",
    },
    transparent: transparentButton,
  },
  inputs: {
    primary: {
      borderColor: grays[4],
      borderRadius: "4px",
      borderStyle: "solid",
      borderWidth: "1px",
      flex: 1,
      fontSize: 1,
      p: 2,
    },
  },
  label: {
    color: "text.primary",
    fontSize: 1,
    fontWeight: "medium",
    textTransform: "uppercase",
  },
  markdown: {
    a: {
      "&:not(.linkToButton)": {
        "&:hover": {
          color: "yellowDark",
        },
        color: "blueLight",
        textDecoration: "underline",
      },
    },
    blockquote: {
      background: grayBackground,
      ml: 0,
      mr: 0,
      p: 3,
    },
    h1: {
      color: "text.para",
      fontFamily: "primary",
      fontSize: [4, null, null, 6],
      fontWeight: "bold",
      lineHeight: 0,
      mb: 4,
    },
    h2: {
      color: "yellowDark",
      fontSize: [4, null, null, 5],
      fontWeight: "medium",
      lineHeight: 0,
      mb: [1, null, null, 3],
    },
    h3: {
      color: "yellowDark",
      fontSize: 3,
      fontWeight: "medium",
      lineHeight: 0,
      mb: [2, null, null, 3],
    },
    "h4, h5, h6": {
      fontSize: [1, null, null, 2],
      fontWeight: "medium",
      mb: 3,
      mt: 0,
    },
    img: {
      width: "100%",
    },
    p: {
      ":not(:last-child)": {
        color: "text.para",
        mb: 3,
      },
      fontSize: [1, null, null, 2],
      fontWeight: "medium",
    },
    "pre, code": {
      bg: grayBackground,
      borderRadius: 1,
      display: "block",
      fontFamily: "monospace",
      maxWidth: "100%",
      minWidth: "100px",
      my: 3,
      overflowX: "auto",
      p: 4,
      whiteSpace: "pre",
    },
    // eslint-disable-next-line sort-keys
    ol: {
      li: {
        ":not(:last-child)": {
          mb: 2,
        },
      },
    },
    ul: {
      li: {
        "&:before": {
          color: "yellowDark",
          content: "'•'",
          display: "inline-block",
          fontWeight: "bold",
          left: -3,
          position: "absolute",
          top: 0,
        },
        ":not(:last-child)": {
          mb: 2,
        },
        position: "relative",
      },
      listStyle: "none",
    },
    "ul, ol": {
      color: "text.para",
      fontSize: [1, null, null, 2],
      lineHeight: 1,
      mb: 2,
      my: 3,
      pl: 4,
    },
  },
};

export default {
  baseFontSizesForBreakpoint: ["5vw", "4vw", "2.5vw", "1.5vw", "18px"],
  breakpoints,
  colors,
  designElement,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  mediaQueries,
  radii,
  shadows,
  sizes,
  space,
  variants,
  zIndices,
};
