import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const FooterContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFooterJson {
          edges {
            node {
              privacyPolicy {
                label
                link
              }
              socialmediaTitle
              termsOfUse {
                label
                link
              }
              linkTitle
              contactTitle
              hideSocialMedia
              hideContactDetails
              rights
              id
              socialmediacontacts {
                label
                link
              }
            }
          }
        }
        allContactJson {
          edges {
            node {
              contactTitle
              emailsaddresses {
                label
                mailid
              }
              phonenumbers {
                label
                number
              }
            }
          }
        }
        allPagesJson {
          edges {
            node {
              id
              pages {
                title
                url
                hidePage
              }
            }
          }
        }
      }
    `}
    render={({
      allFooterJson: { edges: footerData },
      allContactJson: { edges: contactData },
      allPagesJson: { edges: pagesData },
    }) =>
      children(
        footerData.map((e) => ({
          ...e.node,
        })),
        contactData.map((e) => ({
          ...e.node,
        })),
        pagesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

FooterContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default FooterContainer;
