import { css } from "styled-components";
import { navigate } from "gatsby";
import { brandSettings } from "../components/StyledComponents/vars";

export const media = Object.keys(brandSettings.breakPoints).reduce(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${brandSettings.breakPoints[label]}) {
        ${css(...args)}
      }
    `;

    return acc;
  },
  {}
);

export const NavMenuToggle = () => {
  document.getElementsByTagName("BODY")[0].classList.toggle("open-menu");
};

export const ModalToggle = () => {
  document.getElementsByTagName("HTML")[0].classList.toggle("open-modal");
};

export const CustomNavigate = (navigateTo) => {
  navigate(navigateTo);
};

export const ImageName = (image, sep) => {
  if (sep === ".") return image.split(/\.(?=[^\.]+$)/)[1];
  if (sep === "/") return image.split(/\/(?=[^\/]+$)/)[1];
};

export let SelectedSubject = null;
export const SelectASubject = (sub, services) => {
  SelectedSubject = services[services.indexOf(sub)];
};

export const titleToPath = (string) =>
  string
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
