import React, { useContext, useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { globalHistory } from "@reach/router";
import { ThemeContext } from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Box from "../../base/Box";
import Container from "../Container";
import BrandLogo from "../BrandLogo";
import Navigation from "../Navigation";
import Hamburger from "../Hamburger";
import PagesContainer from "../../containers/PagesContainer";

const Header = () => {
  const theme = useContext(ThemeContext);
  const [toggled, setToggled] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === "PUSH") setToggled(false);
      }),
    [toggled]
  );

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y > 0;
      if (isShow !== isScrolled) setIsScrolled(isShow);
    },
    [isScrolled],
    null,
    true
  );

  const containerStyled = {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    position: "static",
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const headerStyled = {
    ":before": {
      bg: "white",
      bottom: 0,
      boxShadow: [isScrolled ? 3 : 0, null, null, isScrolled ? 3 : 0],
      content: '""',
      left: 0,
      // opacity: isScrolled ? 1 : 0,
      position: "absolute",
      right: 0,
      top: 0,
      transition: "all .55s 0s ease",
      zIndex: -1,
    },
    "~ main": {
      mt: [
        theme.sizes.heights.header[0],
        null,
        null,
        theme.sizes.heights.header[1],
      ],
    },
    borderBottom: "0px solid",
    borderColor: "borderLight",
    color: "blueDark",
    left: 0,
    position: "fixed",
    right: 0,
    zIndex: 5,
  };

  const getTitleAndLabel = (menuList) =>
    menuList.map((item) => ({
      label: item.title,
      path: item.url,
      submenu: item.subPages ? getTitleAndLabel(item.subPages) : null,
    }));

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              logo
              siteName
            }
          }
        }
      `}
      render={(data) => {
        const { siteName, logo } = data.site.siteMetadata;
        return (
          <PagesContainer>
            {(pagesData) => {
              const { pages } = pagesData.find((item) => item.id === "pages");
              const allPages = pages.filter((i) => !i.hidePage);
              const pageList =
                allPages && allPages.length > 0
                  ? getTitleAndLabel(allPages)
                  : [];
              return (
                <Box as="header" sx={headerStyled}>
                  <Container sx={containerStyled}>
                    <BrandLogo
                      alt={siteName}
                      path="/"
                      ratio={[94, 32]}
                      src={logo}
                      sx={{
                        color: "white",
                        div: {
                          justifyContent: "flex-start",
                        },
                        flex: "none",
                        my: 3,
                        px: [1, null, null, null],
                        width: "17rem",
                      }}
                    />
                    <Hamburger
                      innerSx={{ m: 1 }}
                      onClick={handleToggle}
                      sx={{
                        bg: "transparent",
                        borderRadius: 1,
                        // color: toggled ? "border" : "border",
                        color: "border",
                        display: ["inline-block", null, null, null, "none"],
                        flex: "none",
                        position: toggled ? "fixed" : "relative",
                        right: toggled ? "1.5rem" : "0rem",
                        width: "30px",
                        zIndex: toggled ? 6 : 1,
                      }}
                      toggled={toggled}
                    />
                    {pageList.length > 0 && (
                      <Navigation
                        ignore={["Home"]}
                        navLinks={pageList}
                        toggled={toggled}
                      />
                    )}
                  </Container>
                </Box>
              );
            }}
          </PagesContainer>
        );
      }}
    />
  );
};

export default Header;
